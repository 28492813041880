import React, { useState } from "react";
import { Link, graphql, useStaticQuery } from "gatsby";
import { slide as Menu } from "react-burger-menu";

export default function NavBar() {
  const data = useStaticQuery(graphql`
    query menuQuery($slug: String! = "") {
      allMarkdownRemark(
        sort: { order: ASC, fields: [frontmatter___countryName] }
        filter: {
          frontmatter: { countryName: { nin: ["Royaume-Uni", "Suisse"] } }
        }
      ) {
        edges {
          node {
            id
            frontmatter {
              title
              countryName
              serviceName
              iso3166_2
              templateKey
              date(formatString: "MMMM DD, YYYY")
              slug
            }
            fields {
              slug
            }
          }
        }
      }
      allCountries {
        edges {
          node {
            id
            name
            flag
            alpha2Code
            alpha3Code
          }
        }
      }
      markdownRemark(frontmatter: { slug: { eq: $slug } }) {
        html
        frontmatter {
          title
          slug
          countryName
          countryNameArticle
          phoneNumberA
          phonePriceA
          # phoneNumberB
          # phonePriceB
          instructionsPrixPays
          instructionsAppelPays
          # instructionsServiceClientPays
        }
      }
    }
  `);

  const [menuState, setMenuOpen] = useState({ menuOpen: false });

  const closeMenu = () => {
    setMenuOpen({ menuOpen: false });
  };

  const { edges: posts } = data.allMarkdownRemark;

  return (
    <>
      <div id="top-nav" className="sticky-top">
        <div id="side-menu">
          <Menu
            isOpen={menuState.menuOpen}
            pageWrapId={"page-wrap"}
            outerContainerId={"outer-container"}
          >
            <h2>
              <Link className="bm-item" to="/" onClick={() => closeMenu()}>
                Accueil
              </Link>
            </h2>
            <h2>Pays</h2>
            <ul className="list-unstyled">
              {posts
                .filter(
                  (post) => post.node.frontmatter.templateKey === "page-pays"
                )
                .map(({ node: post }) => {
                  return (
                    <li className="" key={post.id}>
                      <Link
                        className="bm-item"
                        to={`${post.frontmatter.slug}`}
                        onClick={() => closeMenu()}
                      >
                        {post.frontmatter.countryName}
                      </Link>
                    </li>
                  );
                })}
            </ul>
            <h2>Services</h2>
            <ul className="list-unstyled">
              {posts
                .filter(
                  (post) => post.node.frontmatter.templateKey === "page-service"
                )
                .map(({ node: post }) => {
                  return (
                    <li className="" key={post.id}>
                      <Link
                        className="bm-item"
                        to={`${post.frontmatter.slug}`}
                        onClick={() => closeMenu()}
                      >
                        {post.frontmatter.serviceName}
                      </Link>
                    </li>
                  );
                })}
            </ul>
            <h2>Aide</h2>
            <ul className="list-unstyled">
              {posts
                .filter(
                  (post) => post.node.frontmatter.templateKey === "page-ade"
                )
                .map(({ node: post }) => {
                  return (
                    <li className="" key={post.id}>
                      <Link
                        className="bm-item"
                        to={`${post.frontmatter.slug}`}
                        onClick={() => closeMenu()}
                      >
                        {post.frontmatter.title}
                      </Link>
                    </li>
                  );
                })}
            </ul>
          </Menu>
        </div>
      </div>
    </>
  );
}
