import React, { useState, useMemo } from "react";
import { graphql, useStaticQuery, Link } from "gatsby";
import { Location } from "@reach/router";
import { Helmet } from "react-helmet";
import CookieConsent from "react-cookie-consent";
import ReactGA from "react-ga";
import NavBar from "../components/navigation/main-navbar";

import logo from "./logo.png";

import "./index.scss";

// import Footer from "../components/footer";

class Footer extends React.Component {
  render() {
    return (
      <>
        <div className="card p-3 mt-3">
          <div style={{ textAlign: "center" }}>
            Appelerdeletranger.com, le premier service pour contacter tous les
            numéros courts, spéciaux et surtaxés français depuis l’étranger.
            <br />© 2010-2024 aheho. Tous droits réservés.
          </div>
        </div>
      </>
    );
  }
}

const DefaultLayout = ({ children, location }) => {
  const data = useStaticQuery(graphql`
    query layoutQuery($slug: String! = "") {
      allMarkdownRemark(
        sort: { order: ASC, fields: [frontmatter___countryName] }
        filter: {
          frontmatter: { countryName: { nin: ["Royaume-Uni", "Suisse"] } }
        }
      ) {
        edges {
          node {
            id
            frontmatter {
              title
              description
              countryName
              serviceName
              iso3166_2
              templateKey
              date(formatString: "MMMM DD, YYYY")
              slug
            }
            fields {
              slug
            }
          }
        }
      }
      allCountries {
        edges {
          node {
            id
            name
            flag
            alpha2Code
            alpha3Code
          }
        }
      }
      markdownRemark(frontmatter: { slug: { eq: $slug } }) {
        html
        frontmatter {
          title
          description
          slug
          countryName
          countryNameArticle
          phoneNumberA
          phonePriceA
          # phoneNumberB
          # phonePriceB
          instructionsPrixPays
          instructionsAppelPays
          # instructionsServiceClientPays
        }
      }
      site {
        siteMetadata {
          siteUrl
        }
      }
    }
  `);

  const title = useMemo(() => {
    const potentialTitles = data.allMarkdownRemark.edges.map(
      (el) => el.node.frontmatter
    );
    let tmpTitle = "";
    const item = potentialTitles.find(({ slug }) =>
      slug.includes(location.pathname)
    );
    if (item) {
      tmpTitle = item.description ?? item.title;
    } else {
      tmpTitle = `Appeler les numéros de Services à Valeur Ajoutées (SVA) courts, surtaxés et en 08 depuis l'étranger?`;
    }
    return tmpTitle;
  }, [data]);

  const canonical = useMemo(() => {
    const potentialTitles = data.allMarkdownRemark.edges.map(
      (el) => el.node.frontmatter
    );
    let canonical = "";
    if (potentialTitles.map((el) => el.slug).includes(location.pathname)) {
      const item = potentialTitles.filter((el) => {
        return el.slug === location.pathname;
      })[0];

      if (item) {
        canonical = item.url;
      }
    }
    return canonical;
  }, [data]);

  return (
    <>
      <Helmet>
        <title>{title}</title>
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        <meta
          name="description"
          content="Vous devez parler avec la Sécu, EDF ou tout autre service français depuis l’étranger?
          appelerdeletranger.com c’est simple, immédiat et sans tracas."
        />
        <link rel="canonical" href={canonical} />
      </Helmet>
      <NavBar />
      <div className="container">
        <div className="row pt-3">
          <div className="col-2 logo">
            <Link to={`/`}>
              <img src={logo} alt="logo d'appeler de l'étranger" />
            </Link>
          </div>
          <div className="col-8">
            <h1 className="header">{title}</h1>
          </div>
        </div>
        {children}
        <Footer posts={data} />
        <CookieConsent
          enableDeclineButton
          flipButtons
          acceptOnScroll
          onAccept={() => {
            ReactGA.initialize("UA-10572224-2");
          }}
          location="bottom"
          buttonText="J'accepte"
          declineButtonText="Je refuse"
          cookieName="ADEGDPR-ga"
          style={{ background: "#2B373B" }}
          // buttonStyle={{ color: '#4e503b', fontSize: '13px' }}
          // expires={150}
          sameSite="secure"
          // overlay={true}
        >
          Ce site web utilise des cookies.{" "}
          <span style={{ fontSize: "10px" }}>
            Les cookies nous permettent d'analyser notre trafic.
          </span>
        </CookieConsent>
      </div>
    </>
  );
};

export default DefaultLayout;
